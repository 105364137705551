import SmartCropImage from "@/components/core/SmartCropImage"
import DOMPurify from "isomorphic-dompurify"
import { servicesAnalyticsClick } from "@/components/Shared/HeaderNavTabs/analytics/index.js"
import {
  closestAnchorHref,
  modifyHref,
} from "@/components/Shared/HeaderNavTabs/utils"

const caretimg = "/icons/bold.svg"
const caretimgWhite = "/icons/bold-white.svg"

const ImageBlock = props => {
  const {
    itemType,
    menuType,
    newTabImage,
    imageLink,
    ctaLabel,
    selectedTab,
    imageFileReference,
    imageAltText,
    imageTitle,
    imageCtaLink,
    newTabImageCta,
    darkThemeFlag,
    smartCrop,
  } = props
  return (
    <div className={`${itemType?.appliedCssClassNames} ${menuType}`}>
      <a
        style={{
          cursor: "pointer",
        }}
        target={newTabImage ? "_blank" : "_self"}
        href={imageLink ? modifyHref(imageLink) : null}
        rel="noreferrer"
        onClick={e => {
          servicesAnalyticsClick({
            text: ctaLabel,
            link: imageLink,
            Leveltwo: selectedTab,
            L1: "",
            imgcheck: true,
          })
          closestAnchorHref(e) && e.preventDefault()
        }}
      >
        <SmartCropImage
          url={imageFileReference}
          className="cmp-image__image"
          smartCrop={smartCrop}
          altText={imageAltText ?? ""}
        />
      </a>
      <span
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(imageTitle),
        }}
      />
      {imageCtaLink ? (
        <div className="image-cta">
          <div className="div-of-cta">
            <a
              target={newTabImageCta ? "_blank" : "_self"}
              href={imageCtaLink ? modifyHref(imageCtaLink) : null}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(ctaLabel),
              }}
              onClick={e => {
                servicesAnalyticsClick({
                  text: ctaLabel,
                  link: imageCtaLink,
                  Leveltwo: selectedTab,
                })
                closestAnchorHref(e) && e.preventDefault()
              }}
              rel="noreferrer"
            ></a>
          </div>
          <div className="cta-link-caret">
            <img
              src={darkThemeFlag ? caretimgWhite : caretimg}
              alt="caret-icon"
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}
export default ImageBlock
