import React, { useEffect } from "react"
import DOMPurify from "isomorphic-dompurify"
import { servicesAnalyticsClick } from "@/components/Shared/HeaderNavTabs/analytics/index.js"
import Image from "next/image"
import UpAccordion from "@/public/icons/up-accordion.svg"
import DownAccordion from "@/public/icons/down-accordion.svg"
import NavigationAccordion from "@/components/Shared/HeaderNavTabs/NavigationAccordion/index"
import {
  closestAnchorHref,
  modifyHref,
} from "@/components/Shared/HeaderNavTabs/utils"
import { sanitizeText } from "@/utils/helper"
import ImageBlock from "@/components/Shared/HeaderNavTabs/ImageBlock/index"

const TabPanel = props => {
  const {
    itemType,
    selectedId,
    levelOneLengthsArray,
    navOpen,
    selectedNavTabIndex,
    navTabIndex,
    setActiveIndex,
    setSelectedId,
    selectedTab,
    staticClasses,
    handleMouseLeave,
    smartCrop,
    darkThemeFlag,
    handleAccordionClick,
    getInternalLinkURLS,
    menuType,
    onClickNav,
    activeIndex,
    navTitle,
    levelOneNavMulti,
    multiCTA,
    firstImagesblock,
    secondImagesblock,
  } = props

  const caretimg = "/icons/bold.svg"
  const externalicon = "/icons/externalicon.svg"
  const caretimgWhite = "/icons/bold-white.svg"
  const externalIconWhite = "/icons/external link.svg"

  const isMobile = typeof window !== "undefined" && window.innerWidth <= 1200
  useEffect(() => {
    if (levelOneLengthsArray[selectedNavTabIndex] !== -1) {
      setActiveIndex(levelOneLengthsArray[selectedNavTabIndex])
      setSelectedId(levelOneLengthsArray[selectedNavTabIndex])
    }
  }, [])

  const {
    newTabFirstImage = false,
    firstImageLink = "",
    ctaLabel: firstCtaLabel = "",
    firstImageFileReference = "",
    ctaLink: firstImageCtaLink = "",
    altText: firstImageAltText = "",
    firstImageTitle = "",
    ctaNewTab: newTabFirstImageCta = false,
  } = firstImagesblock

  const {
    newTabSecondImage = false,
    secondImageLink = "",
    ctaLabel: secondCtaLabel = "",
    secondImageFileReference = "",
    ctaLink: secondImageCtaLink = "",
    altText: secondImageAltText = "",
    secondImageTitle = "",
    ctaNewTab: newTabSecondImageCta = false,
  } = secondImagesblock

  return (
    <div
      key={`navTabs-${navTabIndex}`}
      role="tabpanel"
      tabIndex={"-1"}
      className={`cmp-tabs__tabpanel ${
        navOpen === itemType["cq:panelTitle"]
          ? "cmp-tabs__tabpanel--active"
          : ""
      }`}
    >
      <div className={`${staticClasses.CONTAINER}`}>
        <div className="cmp-container">
          <div className="aem-Grid aem-Grid--11 aem-Grid--default--11 ">
            <div className="globalnavigation aem-GridColumn aem-GridColumn--default--11">
              <div className="gbh-full-w-menu" onMouseLeave={handleMouseLeave}>
                <div className="bg-dim">
                  <div className="gbh-full-w-menu__inner" id="Mouse id">
                    <div className="gbh-full-max-width">
                      <div
                        className={`menu-container ${
                          darkThemeFlag ? "dark-theme" : "light-theme"
                        } ${menuType}`}
                      >
                        <div
                          className={`gbh-full-w-menu__left-cont ${menuType}`}
                        >
                          <div className="gbh-full-w-menu__left-cont__top">
                            <div className="levelZero">
                              <ul className="nav flex-column gbh-full-w-menu__left-cont__menu-list">
                                <button
                                  className="gbh-mob-back-btn"
                                  onClick={e => {
                                    e.preventDefault()
                                    onClickNav(
                                      {
                                        "cq:panelTitle": navOpen,
                                      },
                                      "mobile back btn"
                                    )
                                  }}
                                >
                                  {selectedTab}
                                </button>
                                <div className="level-one-container">
                                  <div className="level-one-sub-container">
                                    {navTitle ? (
                                      <div className="group-title">
                                        {navTitle}
                                      </div>
                                    ) : null}

                                    <div className="level-one-nav-multi">
                                      {levelOneNavMulti?.map((navItem, id) => {
                                        const { levelTwoNavMulti = [] } =
                                          navItem || {}
                                        return (
                                          <div
                                            key={navItem + id}
                                            className={`levelTwoMapping ${
                                              menuType +
                                              (levelOneNavMulti?.length === 1
                                                ? "-one-col"
                                                : "")
                                            }`}
                                          >
                                            {levelTwoNavMulti?.map(
                                              (levelTwo, index) => {
                                                const {
                                                  newTabGroup: newTab = false,
                                                  groupTitle = "",
                                                  groupLink = "",
                                                  levelThreeNavMulti = [],
                                                  hrefLink = groupLink
                                                    ? modifyHref(groupLink)
                                                    : null,
                                                } = levelTwo || {}

                                                return (
                                                  <li
                                                    key={navItem + index}
                                                    className={
                                                      groupTitle?.includes(
                                                        "carrot-icon"
                                                      )
                                                        ? "carot-title"
                                                        : activeIndex ===
                                                            index &&
                                                          selectedId === id &&
                                                          levelTwo
                                                            ?.levelThreeNavMulti
                                                            ?.length > 0 &&
                                                          navTabIndex !== null
                                                        ? "tile-bg"
                                                        : ""
                                                    }
                                                  >
                                                    <div
                                                      onClick={
                                                        isMobile
                                                          ? () =>
                                                              handleAccordionClick(
                                                                index,
                                                                id,
                                                                navTabIndex
                                                              )
                                                          : null
                                                      }
                                                      key={index}
                                                      className="menu-item-tiles"
                                                    >
                                                      {isMobile &&
                                                      levelThreeNavMulti?.length >
                                                        0 ? (
                                                        <div
                                                          className={`menuItems ${menuType} ${
                                                            groupTitle?.includes(
                                                              "carrot-icon"
                                                            )
                                                              ? "carot-icon"
                                                              : ""
                                                          }`}
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              DOMPurify.sanitize(
                                                                groupTitle
                                                              ),
                                                          }}
                                                        />
                                                      ) : (
                                                        <a
                                                          target={
                                                            newTab
                                                              ? "_blank"
                                                              : "_self"
                                                          }
                                                          className={`menuItems ${menuType} ${
                                                            groupTitle?.includes(
                                                              "carrot-icon"
                                                            )
                                                              ? "carot-icon"
                                                              : ""
                                                          }`}
                                                          aria-label={
                                                            typeof window !==
                                                              "undefined" &&
                                                            window.location.href.includes(
                                                              hrefLink
                                                            )
                                                              ? `Current Page: ${sanitizeText(
                                                                  groupTitle
                                                                )}`
                                                              : sanitizeText(
                                                                  groupTitle
                                                                )
                                                          }
                                                          href={hrefLink}
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              DOMPurify.sanitize(
                                                                groupTitle
                                                              ),
                                                          }}
                                                          onClick={e => {
                                                            servicesAnalyticsClick(
                                                              {
                                                                text: groupTitle,
                                                                link: groupLink,
                                                                Leveltwo:
                                                                  selectedTab,
                                                              }
                                                            )

                                                            closestAnchorHref(
                                                              e
                                                            ) &&
                                                              e.preventDefault()
                                                          }}
                                                          rel="noreferrer"
                                                        />
                                                      )}
                                                      {isMobile &&
                                                      levelThreeNavMulti.length >
                                                        0 ? (
                                                        <Image
                                                          src={
                                                            activeIndex ===
                                                              index &&
                                                            selectedId === id &&
                                                            navTabIndex !== null
                                                              ? UpAccordion
                                                              : DownAccordion
                                                          }
                                                          alt="down-accordion icon"
                                                          className="toggleArrow"
                                                        />
                                                      ) : null}
                                                      {groupTitle?.includes(
                                                        "carrot-icon"
                                                      ) ? (
                                                        <div className="navigation-img">
                                                          <img
                                                            src={
                                                              darkThemeFlag
                                                                ? caretimgWhite
                                                                : caretimg
                                                            }
                                                            alt="caret-icon"
                                                          />
                                                        </div>
                                                      ) : null}
                                                      {getInternalLinkURLS(
                                                        groupLink
                                                      ) ? (
                                                        <div className="navigation-external-img">
                                                          <img
                                                            src={
                                                              darkThemeFlag
                                                                ? externalIconWhite
                                                                : externalicon
                                                            }
                                                            alt="external-icon"
                                                          />
                                                        </div>
                                                      ) : null}
                                                    </div>
                                                    {activeIndex === index &&
                                                    selectedId === id &&
                                                    navTabIndex !== null ? (
                                                      <NavigationAccordion
                                                        darkThemeFlag={
                                                          darkThemeFlag
                                                        }
                                                        levelTwo={levelTwo}
                                                        getInternalLinkURLS={
                                                          getInternalLinkURLS
                                                        }
                                                        selectedTab={
                                                          selectedTab
                                                        }
                                                        servicesAnalyticsClick={
                                                          servicesAnalyticsClick
                                                        }
                                                      />
                                                    ) : null}
                                                    {!isMobile
                                                      ? levelThreeNavMulti?.map(
                                                          (
                                                            columnItems,
                                                            index
                                                          ) => {
                                                            const {
                                                              newTab = false,
                                                              link = "",
                                                              label = "",
                                                              hrefLink = link
                                                                ? modifyHref(
                                                                    link
                                                                  )
                                                                : null,
                                                            } = columnItems ||
                                                            {}

                                                            return (
                                                              <li
                                                                key={`navItem-${index}`}
                                                                className="nav-item"
                                                              >
                                                                <a
                                                                  target={
                                                                    newTab
                                                                      ? "_blank"
                                                                      : "_self"
                                                                  }
                                                                  role="link"
                                                                  aria-label={
                                                                    typeof window !==
                                                                      "undefined" &&
                                                                    window.location.href.includes(
                                                                      hrefLink
                                                                    )
                                                                      ? `Current Page: ${sanitizeText(
                                                                          label
                                                                        )}`
                                                                      : sanitizeText(
                                                                          label
                                                                        )
                                                                  }
                                                                  href={
                                                                    hrefLink
                                                                  }
                                                                  onClick={e => {
                                                                    servicesAnalyticsClick(
                                                                      {
                                                                        text: label,
                                                                        link: link,
                                                                        Leveltwo:
                                                                          groupTitle,
                                                                        L1: selectedTab,
                                                                      }
                                                                    )
                                                                    closestAnchorHref(
                                                                      e
                                                                    ) &&
                                                                      e.preventDefault()
                                                                  }}
                                                                  rel="noreferrer"
                                                                >
                                                                  <span
                                                                    className="menu-labels"
                                                                    dangerouslySetInnerHTML={{
                                                                      __html:
                                                                        DOMPurify.sanitize(
                                                                          label
                                                                        ),
                                                                    }}
                                                                  />
                                                                  {getInternalLinkURLS(
                                                                    link
                                                                  ) ? (
                                                                    <div className="navigation-external-img">
                                                                      <img
                                                                        src={
                                                                          darkThemeFlag
                                                                            ? externalIconWhite
                                                                            : externalicon
                                                                        }
                                                                        alt="external-icon"
                                                                      />
                                                                    </div>
                                                                  ) : null}
                                                                </a>
                                                              </li>
                                                            )
                                                          }
                                                        )
                                                      : null}
                                                  </li>
                                                )
                                              }
                                            )}
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="gbh-full-w-menu__right-img">
                          {multiCTA?.length > 0 ? (
                            <div className="right-img-cta">
                              {multiCTA?.map((ctaItem, index) => {
                                const {
                                  ctaLink = "",
                                  ctaLabel = "",
                                  newTabCTA = false,
                                } = ctaItem
                                return (
                                  <ol
                                    key={`navItem-${index}`}
                                    className="multicta"
                                  >
                                    <li className="navigationcta">
                                      <div className="ctalable">
                                        <a
                                          aria-label={sanitizeText(ctaLabel)}
                                          rel="noreferrer"
                                          target={
                                            newTabCTA ? "_blank" : "_self"
                                          }
                                          href={
                                            ctaLink ? modifyHref(ctaLink) : null
                                          }
                                          onClick={e => {
                                            servicesAnalyticsClick({
                                              text: ctaItem.ctaLabel,
                                              link: ctaItem?.ctaLink,
                                              Leveltwo: selectedTab,
                                            })
                                            closestAnchorHref(e) &&
                                              e.preventDefault()
                                          }}
                                        >
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: DOMPurify.sanitize(
                                                ctaItem.ctaLabel
                                              ),
                                            }}
                                          />
                                        </a>
                                      </div>
                                      <div tabIndex="-1" className="cta-img">
                                        <img
                                          src={
                                            darkThemeFlag
                                              ? caretimgWhite
                                              : caretimg
                                          }
                                          alt="caret-icon"
                                        />
                                      </div>
                                    </li>
                                  </ol>
                                )
                              })}
                            </div>
                          ) : null}

                          <div className={`block-images ${menuType}`}>
                            {firstImagesblock ? (
                              <ImageBlock
                                itemType={itemType}
                                menuType={menuType}
                                newTabImage={newTabFirstImage}
                                imageLink={firstImageLink}
                                ctaLabel={firstCtaLabel}
                                selectedTab={selectedTab}
                                imageFileReference={firstImageFileReference}
                                smartCrop={smartCrop}
                                imageAltText={firstImageAltText}
                                imageTitle={firstImageTitle}
                                imageCtaLink={firstImageCtaLink}
                                newTabImageCta={newTabFirstImageCta}
                                darkThemeFlag={darkThemeFlag}
                              />
                            ) : null}
                            {secondImagesblock &&
                            levelOneNavMulti?.length < 3 ? (
                              <ImageBlock
                                itemType={itemType}
                                menuType={menuType}
                                newTabImage={newTabSecondImage}
                                imageLink={secondImageLink}
                                ctaLabel={secondCtaLabel}
                                selectedTab={selectedTab}
                                imageFileReference={secondImageFileReference}
                                smartCrop={smartCrop}
                                imageAltText={secondImageAltText}
                                imageTitle={secondImageTitle}
                                imageCtaLink={secondImageCtaLink}
                                newTabImageCta={newTabSecondImageCta}
                                darkThemeFlag={darkThemeFlag}
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="productbg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TabPanel
