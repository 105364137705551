import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import Modal from "@/components/core/Modal/Modal"
import useTrigger from "@/hooks/useTrigger"
import { getPartList } from "@/store/features/pdpSlice"
import { preventBodyScroll } from "@/utils/helper"
import { getProjectionPartListData } from "@/utils/ProjectionPartlist.js"
import PartsViewerModal from "@/components/PartsViewer/v2/PartViewerModal"
import { LoaderSpinner } from "@/components/PartsViewer/v2/icon"
import Style from "@/components/PartsViewer/v2/partviewer.module.scss"

const PartsViewer = props => {
  const {
    page = "",
    analyticsCustomData = null,
    onClose = () => {},
    showModal = false,
    productId = "",
    fileMap = [],
    partId = "",
  } = props
  const [parts, setParts] = useState("")
  const [partsheight, setPartsHeight] = useState("")
  const [trigger, triggerAction] = useTrigger()
  const [loading, setLoading] = useState({ status: true, error: false })
  const dispatch = useDispatch()

  useEffect(() => {
    if (showModal) {
      preventBodyScroll(true)
      document
        .querySelector("mavenoid-assistant")
        ?.classList?.add("mavenoid-suppress")
    }
    return () => {
      document
        .querySelector("mavenoid-assistant")
        ?.classList?.remove("mavenoid-suppress")
    }
  }, [showModal])

  useEffect(() => {
    if (productId && fileMap) {
      const obj = {}
      fileMap.forEach(item => {
        obj[item.id] = item.title
      })
      productInfo(obj)
    }
  }, [productId])

  const productInfo = async obj => {
    const resp = await dispatch(getPartList({ productId })).unwrap()
    if (resp?.SVGFiles) {
      const { Info, SVGFiles } = resp
      const diagramFiles = fileMap.map(val => {
        const getfile = SVGFiles?.filter(v => v?.FileName === val?.id)
        return { ...getfile?.[0], ...val }
      })
      const activePart = diagramFiles?.filter(val => val.FileName === partId)
      const selectedFile =
        activePart.length !== 0 ? activePart[0] : diagramFiles[0]
      const priceList = await getProjectionPartListData(selectedFile?.Parts)
      selectedFile.prices = priceList
      setParts({
        fileMap: obj,
        partsInfo: { Info, SVGFiles: diagramFiles },
        selectedFile,
      })
      setLoading({ status: false, error: false })
    } else setLoading({ status: false, error: true })
  }

  const closeModal = () => {
    preventBodyScroll(false)
    onClose()
  }

  const getPartDiagramCloseEventInfo = JSON.stringify({
    eventAction: page + ":modal:interactive parts diagram:close",
    eventName: page + ":modal:interactive parts diagram:close",
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: "close",
    internalLinkPosition: page,
    internalLinkType: page + ":close",
    internalLinkZoneName: page + ":modal:interactive parts diagram",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  })
  let text1
  let text2
  let title

  if (parts?.partsInfo?.Info !== undefined) {
    text1 = parts?.partsInfo?.Info?.["Product Name"]
    text2 = parts?.partsInfo?.Info?.["Short Description"]
    title = text1.replace(new RegExp(text2, "g"), "")
  }
  const prefeedbackController = () => {}
  return (
    <div className={Style.PartDiagramViewer}>
      <div
        className={`partviewer-modal-container part-${partsheight} ${
          !parts ? "loading-text" : ""
        }`}
      >
        <Modal
          showModal={showModal}
          onModalClose={closeModal}
          closeButtonAnalytics={getPartDiagramCloseEventInfo}
          analyticsCustomData={analyticsCustomData || null}
          className="part-diagram-custom"
        >
          {!loading.status ? (
            <PartsViewerModal
              trigger={trigger}
              triggerAction={triggerAction}
              files={parts?.partsInfo?.SVGFiles}
              fileMap={parts?.fileMap}
              prefeedbackController={prefeedbackController}
              skuImage={parts?.partsInfo?.Info?.Thumbnail}
              title={title}
              sku={parts?.partsInfo?.Info?.Cod}
              setParts={setParts}
              selectedFile={parts?.selectedFile}
              loading={loading}
              analytics={{ page: page }}
              setPartsHeight={setPartsHeight}
            />
          ) : (
            <LoaderSpinner />
          )}
        </Modal>
      </div>
    </div>
  )
}

export default PartsViewer
